
import React from 'react';
import './App.css';
import logo from './logo.svg'


function App () {
  return(
    <div>
        <nav class="navbar background">
            <ul class="nav-list">
                <div class="logo">
                    <img src={logo} alt="Vilhu.fi -logo"
                    />
                </div>
            </ul>
        </nav>

        <section class="section">
            <div class="box-main">
                <div class="firstHalf">
                    <h1 class="text-big">
                        Mikä serveri? Mitä varten?
                    </h1>
                    <p class="text-small">

                        Vilhu.fi on kahden parikymppisen opiskelijan pieni harrasteprojekti ja yhteinen ajanviete,
                        joka on laajentunut muodossaan hieman odottamattomiin ulottuvuuksiin.
                        <p class="text-small">
                        ​​​
                        </p>
                        Tarjoamme ilmaisen Minecraft-palvelimen kaikille, jotka vain ovat kiinnostuneet pelaamaan! 
                        Pelimuoto on toistaiseksi vain Survival, mutta olemme kiinnostuneita laajentamaan valikoimaa esimerkiksi luovaan tilaan tai modipaketteihin.
                    </p>
                </div>
            </div>
        </section>
        <section class="section">
            <div class="box-main">
                <div class="firstHalf">
                    <h1 class="text-big" id="program">
                        Entä miten pääsen pelaamaan?
                    </h1>
                    <p class="text-small">
                        Serveri on 1.21.1 versiolla, Java Editionilla, mutta tästä huolimatta voit pelata myös Bedrock Editionilla! Katsothan tarkemmat ohjeet tähän alla olevista valikoista.
                    </p>
                </div>
            </div>
        </section>
        <section class="section">
            <div class="box-main">
                <div class="firstHalf">
                    <h1 class="text-big" id="program">
                        Onko pelissä sääntöjä?
                    </h1>
                    <p class="text-small">
                        On. Pyrimme kuitenkin pitämään säännöstön yksinkertaisena: älä käytä epäreilua etua tuovaa muokkausta (esim. hack client, xray, yms.), äläkä kiusaa muita pelaajia.
                    </p>
                </div>
            </div>
        </section>
        <section class="section">
            <div class="box-main">
                <div class="firstHalf">
                    <h1 class="text-big" id="program">
                        Muuta kysyttävää?
                    </h1>
                    <p class="text-small">
                        Tähän tulee jossakin vaiheessa usein kysyttyjä kysymyksiä sekä niihin vastauksia. Sillä aikaa olethan yhteydessä sähköpostitse: [osoite] 
                    </p>
                </div>
            </div>
        </section>
        <footer className="footer">
            <p className="text-footer">
                Tekijänoikeus © Vilhu, 2024
            </p>
        </footer>
    </div>
  );
  }

export default App;  





